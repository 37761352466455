<template>
	<div>
		<div class="row">
			<div class="col-md-12">
				<div class="pull-right">
					<a-button class="btn btn-success" icon="reload" @click="initModule" />
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4 text-center">
				<div class="card bg-primary border-0">
					<div class="card-body">
						<div class="text-white font-weight-bold">
							<div class="font-size-21">Total de Ventas</div>
							<div class="font-size-18">{{ numeral(totals.matriz + totals.centro).format('$0,0.00') }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4 text-center">
				<div class="card bgGreen border-0">
					<div class="card-body">
						<div class="font-weight-bold">
							<div class="font-size-21" @click="openModal('pacific')">Almacén Matriz</div>
							<div class="font-size-18">{{ numeral(totals.matriz).format('$0,0.00') }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4 text-center">
				<div class="card bgRls border-0">
					<div class="card-body">
						<div class="font-weight-bold">
							<div class="font-size-21" @click="openModal('rls')">Almacén Centro</div>
							<div class="font-size-18">{{ numeral(totals.centro).format('$0,0.00') }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-12">
			<h3 class="text-muted">Por vendedor</h3>
			<a-table :columns="columnsSellers" :dataSource="top10.sellers" :pagination="false">
				<div slot="sellerName" slot-scope="record" class="text-left">
					{{ record.salesman_info }}
				</div>
				<div slot="total" slot-scope="record" class="text-right">
					{{ numeral(record.total).format('$0,0.00') }}
				</div>
			</a-table>
		</div>
		<div class="row">
			<div class="col-md-12">
				<hr class="hrText" data-content="♦" />
			</div>
			<div class="col-md-3" v-if="isUserAdmin">
				<b>Almacén</b>
				<a-select :style="{ width: '100%' }" @change="initModule" v-model="filterBranchOffice">
					<a-select-option value="" selected> Todas </a-select-option>
					<a-select-option value="1"> Matriz </a-select-option>
					<a-select-option value="2"> Centro </a-select-option>
					<a-select-option value="3"> Chalco </a-select-option>
				</a-select>
			</div>
			<div class="col-md-3">
				<b>Tipo de filtro</b>
				<a-select :style="{ width: '100%' }" v-model="filterType">
					<a-select-option value="day"> Por día </a-select-option>
					<a-select-option value="range"> Rango de Fecha </a-select-option>
				</a-select>
			</div>
			<div class="col-md-3">
				<div v-if="filterType == 'range'">
					<b>Rango de Fecha</b>
					<a-range-picker :inputReadOnly="true" style="width: 100%" @change="initModule" v-model="defaultDate" :default-value="defaultDate" :value-format="'YYYY-MM-DD'" :format="'YYYY-MM-DD'" />
				</div>
				<div v-if="filterType == 'day'">
					<b>Por día</b>
					<a-date-picker :inputReadOnly="true" style="width: 100%" @change="initModule" v-model="defaultDate[0]" :default-value="defaultDate[0]" :value-format="'YYYY-MM-DD'" :format="'YYYY-MM-DD'" />
				</div>
			</div>
			<div class="col-md-3">
				<b>Vendedor</b>
				<a-select :style="{ width: '100%' }" v-model="salesman_info" @change="initModule">
					<a-select-option value="" selected> Todos </a-select-option>
					<a-select-option value="GAEL"> GAEL - Gael tapia </a-select-option>
					<a-select-option value="CEDIS"> CEDIS - Centro de Distribución </a-select-option>
				</a-select>
			</div>
		</div>
		<div class="row pt10">
			<div class="col-md-12">
				<a-table :columns="columns" :dataSource="tickets" :pagination="false">
					<div slot="id" slot-scope="record">
						{{ record.id }}
						<div v-if="record.salesman_info">
							<b>{{ record.salesman_info }}</b>
						</div>
					</div>
					<div slot="total" slot-scope="record">
						{{ numeral(record.total).format('$0,0.00') }}
						<div>
							<span v-if="record.payment_cash > 0 && !record.cart">
								<a-tag color="green">
									{{ numeral(record.payment_cash).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/cash.png" height="20" />
										Efectivo
									</span>
								</a-tag>
								<br />
							</span>
							<span v-else-if="record.payment_cash > 0 && record.cart">
								<a-tag color="green">
									{{ numeral(record.payment_cash).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/cash.png" height="20" />
										Efectivo
									</span>
								</a-tag>
								<br />
							</span>
							<div v-if="record.payment_bank_terminal > 0">
								<a-tag color="blue">
									{{ numeral(record.payment_bank_terminal).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/credit-card.png" height="20" />
										Terminal
									</span>
								</a-tag>
								<br />
							</div>
							<span v-if="record.payment_transfer > 0">
								<a-tag color="orange">
									{{ numeral(record.payment_transfer).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/money-transfer.png" height="20" />
										Transferencias
									</span>
								</a-tag>
								<br />
							</span>
							<span v-if="record.payment_paypal > 0">
								<a-tag color="purple">
									{{ numeral(record.payment_paypal).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/paypal.png" height="20" />
										PayPal
									</span>
								</a-tag>
								<br />
							</span>
							<span v-if="record.payment_mercadopago > 0">
								<a-tag color="cyan">
									{{ numeral(record.payment_mercadopago).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/mercadopago.png" height="20" />
										MercadoPago
									</span>
								</a-tag>
								<br />
							</span>
							<span v-if="record.payment_aplazo > 0 && !record.cart">
								<a-tag color="gray" style="color: #000">
									{{ numeral(record.payment_aplazo).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/cash.png" height="20" />
										Aplazo
									</span>
								</a-tag>
								<br />
							</span>
						</div>
					</div>
					<div slot="cart" slot-scope="record">
						<div class="text-left">
							<div v-for="(element, index) in record.cart" :key="index">
								<div>
									{{ element.quantity }}x {{ element.product.sku }} - {{ element.product.label }} - {{ numeral(element.product.prices.sale_price).format('$0,0.00') }}
									<span v-if="element.quantity > 1">- ({{ numeral(element.quantity * element.product.prices.sale_price).format('$0,0.00') }})</span>
								</div>
							</div>
						</div>
					</div>

					<div slot="created_at" slot-scope="record">
						<div>{{ moment(record.created_at).tz('America/Mexico_City').format('DD-MM-YYYY') }}</div>
						<div>{{ moment(record.created_at).tz('America/Mexico_City').format('HH:mm:ss') }} hrs.</div>
					</div>
					<div slot="warehouse" slot-scope="record">
						<div v-if="record.branch_office_id == 1">Matriz</div>
						<div v-if="record.branch_office_id == 2">Centro</div>
						<div v-if="record.branch_office_id == 3">Chalco</div>
						<div>
							<b>{{ record.channel }}</b>
						</div>
					</div>
					<div slot="action" slot-scope="record">
						<a-button class="btn btn-success" size="small" icon="printer" @click="onPrintTicket(record)" v-if="record.from_panel || record.from_rls" />
						<div v-else>{{ record.pacific }}</div>
						<div v-if="record.active < 1">CANCELADO</div>
						<!-- <a-button class="btn btn-warning ml7" size="small" icon="file-pdf" @click="onBillSale(record.id)" v-if="record.from == 'rls' && !record.billed" /> -->
					</div>
				</a-table>
			</div>
		</div>
		<div class="row pt20">
			<div class="col-md-12 text-center">
				<h3 class="text-muted">Top 10 en ventas</h3>
				<hr />
			</div>
			<div class="col-md-6">
				<h3 class="text-muted">Por ingreso</h3>
				<a-table :columns="columnsTop10" :dataSource="top10.product" :pagination="false">
					<div slot="index" slot-scope="record">
						{{ record.index }}
					</div>
					<div slot="label" slot-scope="record" class="text-left">
						{{ record.label }}
					</div>
					<div slot="quantity" slot-scope="record">
						{{ record.quantity }}
					</div>
					<div slot="amount" slot-scope="record" class="text-right">
						{{ numeral(record.amount).format('$0,0.00') }}
					</div>
				</a-table>
			</div>
			<div class="col-md-6">
				<h3 class="text-muted">Por canal de venta</h3>
				<a-table :columns="columnsTop10Channel" :dataSource="top10.channel" :pagination="false">
					<div slot="index" slot-scope="record">
						{{ record.index }}
					</div>
					<div slot="quantity" slot-scope="record">
						{{ record.quantity }}
					</div>
					<div slot="amount" slot-scope="record" class="text-right">
						{{ numeral(record.amount).format('$0,0.00') }}
					</div>
					<div slot="channel" slot-scope="record" class="text-left">
						{{ record.channel }}
					</div>
				</a-table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import utilities from '@/services/utilities'
import numeral from 'numeral'
import moment from 'moment'

const dateFormat = 'YYYY-MM-DD'
//
export default {
	name: 'salesmanComponent',
	directives: {},
	computed: {
		...mapGetters('pos', ['filters']),
		...mapGetters('user', ['user']),
		isUserAdmin() {
			return this.user.is_admin ? true : false
		},
	},
	data() {
		return {
			dateFormat,
			localData: {},
			filterBranchOffice: '',
			filterType: 'range',
			salesman_info: '',
			top10: {
				product: [],
				channel: [],
				sellers: [],
			},
			defaultDate: [moment().startOf('month'), moment().endOf('month')],
			totals: {
				centro: 0,
				matriz: 0,
				chalco: 0,
			},
			payload: {
				date: [moment().startOf('month').format(dateFormat), moment(moment().endOf('month')).format(dateFormat)],
				company: '',
			},
			columnsTop10: [
				{
					title: '#',
					scopedSlots: { customRender: 'index' },
					align: 'center',
					width: '10%',
				},
				{
					title: 'Producto',
					scopedSlots: { customRender: 'label' },
					align: 'center',
					width: '60%',
				},
				{
					title: 'Cantidad',
					scopedSlots: { customRender: 'quantity' },
					align: 'center',
					width: '15%',
				},
				{
					title: 'Total',
					scopedSlots: { customRender: 'amount' },
					align: 'center',
					width: '15%',
				},
			],
			columnsTop10Channel: [
				{
					title: '#',
					scopedSlots: { customRender: 'index' },
					align: 'center',
					width: '10%',
				},
				{
					title: 'Canal',
					scopedSlots: { customRender: 'channel' },
					align: 'center',
					width: '15%',
				},
				{
					title: 'Cantidad',
					scopedSlots: { customRender: 'quantity' },
					align: 'center',
					width: '15%',
				},
				{
					title: 'Total',
					scopedSlots: { customRender: 'amount' },
					align: 'center',
					width: '15%',
				},
			],
			columnsSellers: [
				{
					title: 'Nombre',
					scopedSlots: { customRender: 'sellerName' },
					align: 'center',
				},
				{
					title: 'Total',
					scopedSlots: { customRender: 'total' },
					align: 'center',
					width: '15%',
				},
			],
			columns: [
				{
					title: '# / Vendedor',
					scopedSlots: { customRender: 'id' },
					align: 'center',
					width: '15%',
				},
				{
					title: 'Almacén / Canal',
					scopedSlots: { customRender: 'warehouse' },
					align: 'center',
					width: '10%',
				},
				{
					title: 'Carrito',
					scopedSlots: { customRender: 'cart' },
					align: 'center',
					width: '40%',
				},
				{
					title: 'Total',
					scopedSlots: { customRender: 'total' },
					align: 'center',
					width: '10%',
				},
				{
					title: 'Fecha',
					scopedSlots: { customRender: 'created_at' },
					align: 'center',
					width: '10%',
				},
				{
					title: 'Info ticket',
					scopedSlots: { customRender: 'action' },
					align: 'right',
					width: '15%',
				},
			],
			tickets: [],
		}
	},
	mounted() {
		this.initModule()
	},
	methods: {
		numeral,
		moment,
		initModule(date, dateString) {
			//
			let payload = {}

			if (this.filterType == 'range') {
				if (!!dateString && dateString.length) {
					payload = {
						startDate: moment(dateString[0]).format('YYYY-MM-DD'),
						endDate: moment(dateString[1]).format('YYYY-MM-DD'),
					}
				} else {
					payload = {
						startDate: moment(this.defaultDate[0]).format('YYYY-MM-DD'),
						endDate: moment(this.defaultDate[1]).format('YYYY-MM-DD'),
					}
				}
			} else {
				payload = {
					startDate: moment(this.defaultDate[0]).format('YYYY-MM-DD'),
					endDate: moment(this.defaultDate[0]).format('YYYY-MM-DD'),
				}
			}

			if (!!utilities.objectValidate(this, 'filterBranchOffice', false)) {
				payload.branch_office_id = this.filterBranchOffice
			}

			payload.salesmanInfo = this.salesman_info

			this.tickets = []

			// console.log('payload-->', payload)
			this.$store.dispatch('salesman/GET', payload).then((response) => {
				// console.log('salesman/GET-->', response)
				this.tickets = _.cloneDeep(response.data.tickets)
				this.totals = _.cloneDeep(response.data.totals)
				this.top10.product = _.cloneDeep(response.data.top10.product)
				this.top10.channel = _.cloneDeep(response.data.top10.channel)
				this.top10.sellers = _.cloneDeep(response.data.top10.sellers)
			})
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>